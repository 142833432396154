.footer {
  background-color: $grey;
  color: #fff;
  padding-top: 40px;
  padding-bottom: 40px; 
}

.footer-logo-mm {
	text-align: center;
}

.footer-about-mm {
  color: #fff;
  margin-bottom: 3rem;
  @media (max-width: 767px) {
    & {        
      text-align: center;   
    }
  }

  .col:first-child {    
    @media (min-width: 768px) {
      & {        
        text-align: right;   
      }
    }
  }
}

.logo-mm-pic {
	width: 200px;
  height: 95px;
  display: inline-block;
  background: url(../images/logo-15.svg) 0 0 no-repeat;
  background-size: contain;
}

.copyright {
  display: block;
  text-align: center;
}

.col {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%;
  position: relative;
  @media (max-width: 767px) {
    & {        
      text-align: center; 
      flex-basis: 100%;  
    }
  }
}

.socials {  
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; 

  li {
    display: inline-block;
    margin: .8rem 4px;
    width: 30px;
    height: 30px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: .8;
  }

  a {
    display: block;
    height: 100%;
  }

  .fb {
    background-image: url(../images/fb.svg);
  }

  .insta {
    background-image: url(../images/insta.svg);
  }

  .yt {
    background-image: url(../images/yt.svg);
  }  
}