#shopCart {
  padding: 20px;
  min-width: 270px;
  border-radius: 0px;
  float: right;
  color: #000;
  font-family: Neucha, sans-serif;
  font-size: 18px;
  line-height: 21px;
  position: fixed;
  right: 0;
  background: white;
  top: 101px;
  z-index: 1000;
}

#cartInner {
  border: solid 3px $yellow;
  padding: 15px 20px;

  fieldset {
    border: 0;
    padding: 0;
  }
}

.shk-count {
  border: 1px solid $yellow;
  text-align: center;
}

.shk-del {
  color: $red;
}

.total .name {
  padding-top: 10px;
}

.total .price {
  vertical-align: top;
  padding-top: 10px;
}

.cart-order {
  .name {
    padding-right: 10px;
  }
  .count {
    vertical-align: top;
    width: 55px;
  }
  .shk-count {    
    padding: 4px;
    width: 25px;
  }
}

#butEmptyCart {
  font-size: 12px;
  color: $red;
  opacity: 1;
  transition: opacity 0.4s;
  &:hover {
    color: $red;
    opacity: 0.7;
    transition: opacity 0.4s;
  }
}

.btn {
  font-size: 19px;
  margin-top: 30px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;   
    line-height: 1.4;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.btn-primary:hover {
  background-color: $red;
  transition: background-color 0.4s;
}

.btn-primary:focus, .btn-primary.focus, .btn-primary:visited {
  background-color: $yellow;
  border: 0;
  outline: 0;
}

.recount {
  background-color: transparent;
  border: 1px solid transparent;
  padding-left:0;
  padding-top: 10px;
  &:focus,&:visited {
    outline: 0;
  }
}
.glyphicon {
  font-size: 22px;
  margin-top: 36px;
}



.korzina .cart-tab {
  display:none;
}

.confirm-order {
  padding-top: 150px;
  font-family: Neucha, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #000;
  background-color: #f0f0f0;
  height: auto;
  min-height: 800px;
  
  h5 {
    margin-bottom: 20px;
  }

  #shopCart, #shopOrderForm {
    padding: 20px;
    background-color: #fff;
    font-size: 20px;
  }

  #shopOrderForm fieldset {
    padding: 20px;
    border: 4px solid $yellow;
    @media (max-width: 500px) {
      & {
        padding: 0;
        border: 0;
      }
    }
  }

  #cartInner {
    padding: 20px 20px 40px;
    border: 0;
    @media (max-width: 500px) {
      & {
        padding: 0;
        border: 0;
      }
    }
  }

  #butEmptyCart {
    font-size: 20px;
    color: $red;
    opacity: 1;
    transition: opacity 0.4s;
    top: 20px;
    position: relative;
    display: inline-block;
    margin-bottom: 2rem;
    &:hover {
      color: $red;
      opacity: 0.7;
      transition: opacity 0.4s;
    }
  }

  .shk-count {
    text-align: center;
    border: 1px solid $yellow;
  }

  .errors {
    color: $red;
  }

  .rcnt {
    float: right;
    padding-right: 24px;
    .fa {
      font-size: 15px;
    }
  }

  .total {
    margin-top: 60px;
    .name {
      padding-bottom: 20px;
    }
    .price, .count {
      width: 20%;
      vertical-align: top;
    }
  }

  .cart-order {
    .name {
      padding-bottom: 10px;
    }
  }

  .recount {
    background-color: transparent;
    border: 1px solid transparent;
    padding-left:0;
    padding-top: 10px;
    font-size: 15px;
    &:focus,&:visited {
      outline: 0;
    }
  }

  .req-fields {
    font-size: 15px;
  }

  .btn1 {
    background-image: url(../i/try_btn.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    background-color: transparent;
    border: 1px solid transparent;
    font-family: Neucha, sans-serif;
    font-size: 26px;
    color: #fff;
    text-align: center;
    padding: 30px 30px 30px 30px;    
    height: 115px;
    width: 255px;
    margin-top: 40px;
    margin-left: 215px;
    @media (max-width: 767px) {
      & {
        margin-left: 0;
      }
    }

    b {
      font-weight: normal;
      opacity: 1;
      transition: 0.4s;
      &:hover {
        opacity: 0.7;
        transition: 0.4s;
      }
    }   

    &:focus, &:visited {
      offset: 0;
      outline: 0;
    }
  }
}

.purchases-list {
  padding: 20px;
  background-color: #fff;
  font-size: 20px;

  .box {
    border: 4px solid $yellow;
    padding: 15px;
  }

  .title {
    font-family: Neucha, sans-serif;
    font-weight: normal;
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 0;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    td {
      padding-bottom: 10px;
    }

    .summ {
      vertical-align: top;
      text-align: right;
      min-width: 60px;
    }
  }
}

/*Forms*/
.contact-form .wide, .contact-form textarea {
  width: 100%;  
  background-color: rgba(255,255,255,0);
  border: solid 3px #ccc;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  font-family: Neucha, sans-serif;
  color: $red;
  &:focus {
    border: 3px solid $yellow;
    outline: 0;
  }
}
.vericode {
  background-color: rgba(255,255,255,0);
  border: solid 3px #ccc;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  font-family: Neucha, sans-serif;
  color: $red;
  float: left;
  width: 30%;
  &:focus {
    outline: 1px solid $red;
  }
}
.verimg {
  width: 30%;
  padding-left: 20px;
  float: right;
  @media (max-width: 500px) {
    & {
      width: 50%;
    }
  }
}

.code {
  font-size: 15px;  
  float: left;
  padding-right: 20px;
}
.contact-form input {
  height: 35px;
}
.contact-form .first {
  margin-top: 10px;
}
.contact-form .select {
  font-size: 14px;
}

.cart-form-note {
  font-size: 17px; 
  color: $red;
}

.contact-form .field-couponField {
  width: 180px; 
  height: 40px; 
  font-size: 16px;
}

.error.visible {
  font-family: 'Roboto', sans-serif;
  color: $red;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
  top: -5px;
}

.content-section {
  padding-top: 80px;

  @include tablet-lg {
    & {
      padding-top: 140px;
    }       
  }
}