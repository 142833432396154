.all-books-section {
  padding-top: 80px;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    & {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}

.product-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.simple-button {
  text-transform: uppercase;
  display: block;
  padding-top: 18px;
  padding-bottom: 16px;
  text-align: center;
  cursor: pointer;
  font-size: 21px;
  border: 1px solid $pink;
  text-decoration: none;
  letter-spacing: -0.69px;
  color: $black;
  margin: 20px auto;
  max-width: 320px;

  &:hover {
    background-color: $pink;
    color: #fff;
  }
}

.square-bracket-button {
  text-transform: lowercase;
  display: block;
  padding-top: 18px;
  padding-bottom: 16px;
  text-align: center;
  cursor: pointer;
  font-size: 21px;
  text-decoration: none;
  letter-spacing: -0.69px;
  color: $pink;  
  margin: 0 auto 20px;
  max-width: 320px;

  font-family: Roboto, sans-serif;
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 27px;
    border: 1px solid $pink;
  }

  &::before {
    left: 0;
    border-right: 0;
  }

  &::after {
    right: 0;
    border-left: 0;
  }

  &:hover {    
    color: $black;
  }
}

.product-item {
  padding-left: 15px;
  padding-right: 15px;
  outline: 0 !important;
  @media (max-width: 767px) {
    & {
      text-align: center;
    }
  }

  img {
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      & {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  p {
    padding-right: 1rem;   
    font-size: 14px; 
    @media (max-width: 575px) {
      & {
        text-align: center;
      }
    }    
    @media (min-width: 576px) {
      & {
        min-height: 110px;
      }
    }
    @media (min-width: 768px) {
      & {
        min-height: 126px;
      }
    }
    @media (min-width: 992px) {
      & {
        min-height: 145px;
      }
    }
    @media (min-width: 1200px) {
      & {
        min-height: 95px;
      }
    }
  }
}

.product-price {
  font-family: Roboto, sans-serif;
  font-size: 48px;
  letter-spacing: -1.58px;
  display: inline-block;
  border-top: 1px solid $pink;
  line-height: 58px;
  padding-top: 2px;
  @media (max-width: 767px) {
    & {
      text-align: center;
    }
  }
}