$yellow: #fcc819;
$pink: #f265a8;
$black: #000;
$grey: #55595a;
//Old colors
$orange: #ff8b00;
$red: #e52e0a;
$trans: .35s ease-in-out;

@import "mixins";
@import "slick";
@import "books";
@import "menu-bar";

@font-face {
  font-family: 'Neucha';
  font-weight: eot woff truetype;
  src: url("../fonts/NeuchaRegular.eot?") format("eot"), url("../fonts/NeuchaRegular.woff") format("woff"), url("../fonts/NeuchaRegular.ttf") format("truetype"), url("../fonts/NeuchaRegular.svg#Neucha") format("svg");
}

@import "cart";

*, *:before, *:after {
  box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
}

.img-fluid {
	max-width: 100%;
	height: auto;
	display: block;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.container {
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  @include phone-lg {
    & {
      width: 540px;
      max-width: 100%;
    }
  }
  @include tablet {
    & {
      width: 750px;
      max-width: 100%;
    }
  }
  @include tablet-lg {
    & {
      width: 950px;
      max-width: 100%;
    }
  }
  @include desktop {
    & {
      width: 1140px;
      max-width: 100%;
    }
  }
}

.author-section {
  padding-top: 80px;
  padding-bottom: 0;
  overflow: hidden;
  @include tablet-lg {
    & {
      padding-top: 140px;
      padding-bottom: 0;
    }
  }
}

.author-pic {
	padding: 15px 20px 0 90px;	
  width: 100%;	
	position: relative;
  @include tablet-only {
    & {
      width: 520px;
      margin-left: -60px;
    }
  }
  @include tablet-lg-only {
    & {
      width: 510px;
      margin-left: -60px;
    }
  }
  @include desktop {
    & {
      width: 660px;
      margin-left: -60px;
    }
  }

	&::before {
		content: '';
		display: block;		
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-image: url(../images/event-book/tatiana_ellipse.svg);
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: contain;
		z-index: 2;
		@include tablet-lg {
	    & {
	      background-image: url(../images/event-book/yellow-arrow-around-ts.svg);
	    }
	  }
	}

    &::after {
	    @include tablet-only {
	      & {
	        content: '';
	        display: block;   
	        position: absolute;
	        width: 146px;
	        height: 245px;
	        top: 88px;
	        right: -(146px + 60px);
	        background: url(../images/girl.svg) 0 0 no-repeat;
	        background-size: contain;
	        z-index: 3;
	      }
	    }    
    }

	img {
		position: relative;
		z-index: 1;
	}
}

.author-text {  
  font-weight: 300;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  @include tablet-only {
    & {
      background-image: url(../images/event-book/pink-dashed-border-area.svg);
      padding: 30px;
    }
  }   
  @include tablet-lg {
    & {
      position: absolute;
      right: 0;
      z-index: 3;
      background-image: url(../images/event-book/square_dashes_with_girl.svg);
      padding: 30px 50px 30px 30px;
      font-size: 14px;
	    top: 50px;
      left: 370px;
      right: 45px;
    }
  }
  @include desktop {
    & {
      //font-size: 13px;
      padding: 30px 70px 54px 32px;
      top: 65px;
	    left: 520px;
    }
  }

  &::after {    	
  	@include tablet-lg {
	    & {
	      content: '';
	    	display: block;
	    	position: absolute;
	    	right: -18px;
		    bottom: -105px;
		    width: 100px;
		    height: 170px;
	    	background: url(../images/girl.svg) 0 0 no-repeat;
	    	background-size: contain;
	    }
	  }
  }
}

.author-caption {	
  padding-bottom: 40px;
  line-height: 1.5;
  font-size: 14px;	
  @include tablet {
    & {
      padding-left: 30px;
    }
  }
  @include tablet-lg {
    & {
      padding-bottom: 70px;
      padding-left: 80px;
      padding-top: 110px;
    }
  }
  @include desktop {
    & {      
      padding-top: 0;
    }
  }

  @media screen and (min-width: 1200px) and (max-height: 850px) {
    position: relative;
    padding-left: 0;
    top: -126px;
    z-index: 11;
    text-align: right;
    width: 42%;
    padding-bottom: 42px;
  }

  strong {
    font-size: 20px;
  //line-height: 30px;
  }
}

.author-book-collage {
  margin: 3rem 0 0;

	img {
		display: inline-block;
	}
}

















.book-item p {
	width: 280px;
	height: 53px;
	color: #010100;
	font-size: 14px;
	font-weight: 500;
	padding-top: 12px;
}

.sprunova-bg {
	background-position: 50% 0;
	background-size: auto 70vh;	
	background-repeat: no-repeat;
	position:relative;
	overflow: hidden;
	padding-top: 70vh;
	width: 100%;
	max-width: 625px;
	margin-left: auto;
	margin-right: auto;
	@include phone-xs-only {
    	& {
    		max-width: 375px;
    	}
    }
	@include tablet {
    	& {    		
			width: 625px;
			max-width: initial;
    	}
    }
	@include desktop {
    	& {
    		padding-top: 0;
    		background-size: auto 700px;	
    		background-position: -20px bottom;
    		width: auto;
    		margin-left: 0;
    		margin-right: 0;
    	}
    }
    @include desktop-lg {
    	& {    
    		background-size: auto 90%;	
    		background-position: 0 bottom;
    	}
    }
}


.sprunova-title {	
  	display: inline-block;
	margin-top: 0;
	margin-bottom: 0;
	color: #fff;
	font-size: 20px;	
	padding-left: 15px;
	padding-right: 15px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 30;
	@include desktop {
    	& {
    		position: static;
    	}
    }

	span {
		position: absolute;
		z-index: 1;
	}

	svg {
		height: 70vh;		
		width: auto;
		position: relative;
		z-index: 2;
		display: block;
		@include desktop {
	    	& {
	    		height: 100vh;
	    	}
	    }
	}
}

.main-content {	
	background-color: #fff;	
	@include desktop {
    	& {
    		width: 62%;
			margin-left: auto;
			display: flex;
			position: relative;
    	}
    }
}

.white-fade {
    position: absolute;
    height: 80px;
    top: -80px;
    left: 0;
    right: 0;
    display: block;
    left: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(0deg, #fff 0, rgba(255, 255, 255, 0.01));
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr="#ffffff",endcolorstr="#ffffff",gradienttype=0);
    @include desktop {
    	& {    		
			display: none;
    	}
    }
}

.books-block {
	background-color: #fff;
	position: relative;
	z-index: 10;
	padding-left: 20px;	
	display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    @include desktop {
    	& {
    		justify-content: space-around;
    		flex-grow: 1;
    	}
    }
}

// .copyright {
// 	position: absolute;
//     left: 20px;
//     right: 20px;
//     bottom: 15px;
//     font-size: 14px;
//     display: block;
//     text-align: center;
//     @include desktop {
//     	& {
//     		text-align: left;
//     	}
//     }
// }

.book-item {
	display: inline-block;
	background-position: 0 0; 
	background-repeat: no-repeat;
	background-size: 380px auto;
    padding-top: 180px;
    position: relative;
    padding-right: 325px;
    min-width: 575px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
    @include phone-xs-only {
    	& {
    		background-size: auto 182px;
		    width: 100%;		    
		    position: relative;
		    padding-right: 0;
    		min-width: initial; 
    	}
    }

    @include desktop {
    	& {
    		margin: 0;
    		padding-bottom: 0;
    	}
    }

	.more-button {
		position: relative;
		width: 170px;
		color: #fff;
		font-size: 20px;
		font-weight: 500;		
		text-decoration: none;		
	    padding: 10px 20px;
	    text-align: center;
	    font-family: Neucha, sans-serif;
	    display: inline-block;
	    vertical-align: bottom;

	    @media (min-width: 620px) {
		    & {
		    	position: absolute;
				bottom: -5px;
		    	right: 100px;
		    }
		}

	    svg {
	    	color: #ea5292;
	    	position: absolute; 
	    	z-index: 1;
	    	right: 0;
	    	top: 0;
	    	transition: color .35s ease-in-out;	
	    }

	    &:hover svg {
	    	color: #ffc71c;
	    	transition: color .35s ease-in-out;	
	    }

	    span {
	    	position: relative;
	    	z-index: 2;
	    }
	}

	.girl {		
		width: 65px;
		display: inline-block;
		vertical-align: bottom;
		@media (min-width: 620px) {
		    & {
		    	position: absolute;
				right: 0;
				bottom: 0;
		    }
		}		
	}

	.boy {		
	    width: 85px;
	    @media (min-width: 620px) {
		    & {
		    	position: absolute;
				right: 210px;	   
			    bottom: 0;
		    }
		}

		+ .more-button {			
			@media (min-width: 620px) {
			    & {
			    	right: 20px;
			    }
			}
		}
	}
}

.lang-switcher {
	position: fixed;
	right: 30px;
	top: 20px;
	z-index: 100;
	font-size: 14px;
	@include phone-only {
    	& {
    		right: auto;
    		left: 20px;
    		position: absolute;
    	}
    }

	a {
		color: #000;
		text-decoration: none;
		&.active {
			color: #ffc71c;
		}
	}
}

.slick-dots {
  text-align: center;

  li {
    display: inline-block;
    margin-left: 7px;
    margin-right: 7px;
  }

  button {
    border: 0;
    background-color: #fff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    font-size: 0;
    cursor: pointer;
    transition: $trans;
    outline: 0 !important;

    &:hover {
      background-color: $pink;
      transition: $trans;
    }
  }
}

@import "footer";