$lightgrey: #b5b5b5;

.nav-brand {
  position: absolute;
  top: 6px;
  left: 15px;
  width: 112px;
  height: 38px;
  @include phone-lg {
    & {
      top: 8px;
      width: 138px;
      height: 48px;
    }
  }
  @include tablet-lg {
    & {
      top: 9px;
      width: 235px;
      height: 80px;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.navbar-toggle {
  display: none;

  @include tablet-phone-only {
    & {  
      display: block;
      position: relative;
      float: right;
      right: -15px;
      padding: 18px 14px 19px;      
      background-color: transparent;
      background-image: none;
      border: 1px solid transparent;
      border-radius: 4px;     
      outline: 0 !important;
      cursor: pointer;
    }       
  }   
  @include phone-xs {
    & {
      padding: 10px 14px;
    }       
  }

  .icon-bar {
    //background-color: $black;
    width: 32px;
    height: 3px;
    border-radius: 0;
    display: block;
  }

  .icon-bar + .icon-bar {
    margin-top: 9px;
    
    @include phone-lg {
      & {
        margin-top: 7px;
      }       
    }   
  }
}

.navbar-top {  
  background-color: $grey;
  position: fixed;
  display: table;
  width: 100%;
  min-width: 320px;  
  font-family: 'Roboto', sans-serif;
  z-index: 100;
  margin-bottom: 0;
  
  @include phone-only {
    & {
      position: relative;
    }       
  }

  &.-grey {  
    background-color: $grey;

    .menu, .submenu {
      background-color: $grey;
    }

    svg {
      color: $lightgrey;
    }

    .cart-tab {
      background-color: #707070;
    }

    .cart-counter-informer {
      background-color: $lightgrey;
      color: $grey;
    }

    .navbar-toggle .icon-bar {
      background-color: #fff;
    }

    .lang a {
      color: #fff;

      &.active, &:hover, &::after {
        color: #707070;
      }
    }
  }

  &.-yellow {
    background-color: $yellow;

    .menu, .submenu {
      background-color: $yellow;
    }

    .nav-brand svg {
      color: $grey;
    }

    .cart-tab {
      background-color: #ffa500;
    }

    .menu a {
      color: $grey;

      &.active, &:hover {
        color: #fff;
      }
    }

    .navbar-toggle .icon-bar {
      background-color: $grey;
    }

    .lang a {
      color: #fff;

      &.active, &:hover, &::after {
        color: $grey;
      }
    }

    .cart-counter-informer {
      background-color: $yellow;
      color: $grey;
    }
  }

  &.-orange {
    background-color: $orange;

    .menu, .submenu {
      background-color: $orange;
    }

    .nav-brand svg {
      color: #fff;
    }

    .cart-tab {
      background-color: #ffa500;
    }

    .menu a {
      color: #fff;

      &.active, &:hover {
        color: #fff;
      }
    }

    .navbar-toggle .icon-bar {
      background-color: #fff;
    }

    .lang a {
      color: #fff;

      &.active, &:hover, &::after {
        color: $grey;
      }
    }

    .cart-counter-informer {
      background-color: #fff;
      color: $grey;
    }
  }
 
  .menu {    
    list-style-type: none;
    padding: 0;    
    margin: 0;
    @include tablet-lg {
      & {
        height: 100px;
        float: right;
        position: relative;
        right: 210px;
      }       
    }
    @include tablet-phone-only {
      & {        
        padding: 15px 20px 20px 10px;
        display: none;
        position: fixed;
        top: 62px;
        width: 305px;
        right: 0;
        z-index: 1001;
      }       
    }
    @include phone-xs {
      & {        
        padding-right: 0;   
        //width: 182px;
        text-align: right;
        top: 47px;
      }       
    }
    
    li {
      text-transform: uppercase;      
    }

    > li {
      text-transform: uppercase;
      padding: 0 15px;
      @include tablet-lg  {
        & {
          position: relative;          
          display: inline-block;
          height: 100px;
          line-height: 100px;
        }       
      }

      & > a {
        font-size: 16px; 
        &:not(.has-children) {
          border-bottom: 1px solid transparent; 
        }        
        @include tablet-phone-only  {
          & {          
            width: 100%;          
            font-size: 18px;          
            line-height: 1.4;
            padding-left: 0;
            display: block;
            letter-spacing: 1.5px;
            padding-bottom: 3px;
          }       
        }

        &:hover {
          border-bottom-color: inherit;
          transition: 0.35s;
        }
      }
    }

    a {      
      color: #fff;
      text-decoration: none;  

      &:focus, &:visited {
        outline: 0;
      }

      
      // &:hover {
      //   background-repeat: no-repeat;
      //   opacity: 1;
      //   transition: opacity 0.4s;
      //   @include tablet-lg {
      //     & {
      //       background-image: url(../images/event-book/button-border.svg);            
      //       background-repeat: no-repeat;            
      //     }
      //   }
      //   @include tablet-phone-only  {
      //     & {
      //       background-image: none !important;
      //       opacity: 0.7;
      //       transition: opacity 0.4s;
      //     }       
      //   }
      // }

      // &.short:hover {
      //   @include tablet-lg {
      //     & {            
      //       background-size: 101% auto;
      //       background-position: 0px 6px;
      //     }
      //   }       
      // }

      // &.medium:hover {
      //   @include tablet-lg {
      //     & {            
      //       background-size: 93% auto;
      //       background-position: 2px 1px;
      //     }
      //   }
      // }
    }
  }   

  .submenu {
    margin: 0;
    padding: 0 15px 15px;
    list-style: none;
    line-height: 1.45;
    font-size: 14px;    
    
    @include tablet-lg  {
      & {
        padding: 15px;
        position: absolute;
        left: 0;
        width: 305px;
        top: 75px;
        display: none;
        border-top: 1px solid rgba(#fff, .8);
        font-size: 16px;
      }       
    } 
    li {
      margin-bottom: .5rem;
    }

    a {
      transition: 0.35s;
    }

    a:hover {
      transition: 0.35s;
      transform: translateX(8px);
    }
  }

  .menu-bar {
    float: right;
  }   

  .lang {
    position: absolute;
    right: 125px;
    top: 15px;    
    z-index: 100;
    font-size: 16px;
    @include phone-lg {
      & {
        right: 140px;
        top: 22px;
      }
    }    
    @include tablet-lg {
      & {
        top: 40px;
        text-transform: uppercase;
      }
    }

    a:first-child {
      padding-right: 12px;
      position: relative;

      &::after {
        content: '|';
        right: -8px;
        //color: $black;
        position: relative;
      }
    }

    a {
      //color: #000;
      text-decoration: none;

      &.active, &:hover {
        //color: #fff;
      }
    }
  }
}

.cart-tab {
  position: absolute;
  right: 62px;
  top: 0;
  height: 47px;
  width: 47px;
  z-index: 1000;
  @include phone-lg {
    & {
      height: 62px;
      width: 62px;
      
    }       
  }
  @include tablet-lg {
    & {
      right: 0;  
      height: 100px;
      width: 100px;
    }       
  }

  svg {
    width: 19px;
    height: 13px;    
    position: absolute;
    left: 48%;
    top: 50%;
    transform: translate(-50%,-50%);
    @include phone-lg {
      & {
        width: 30px;
        height: 36px;
      }       
    }
    @include tablet-lg {
      & {
        width: 45px;
        height: 30px;
      }       
    }
  }
}

.cart-counter-informer {
  width: 9px;
  height: 9px;
  line-height: 9px;
  font-size: 7px;
  display: block;
  position: absolute;
  border-radius: 50%;
  text-align: center;  
  right: 6px;
  bottom: 6px;
  @include phone-lg {
    & {
      width: 12px;
      height: 12px;
      line-height: 12px;
      font-size: 10px;
      right: 8px;
      bottom: 8px;
    }       
  }
  @include tablet-lg {
    & {
      width: 19px;
      height: 19px;
      line-height: 19px;
      font-size: 12px;
      right: 10px;
      bottom: 10px;
    }       
  }
}