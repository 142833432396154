//Media
$phone-width: 575px;
$tablet-width: 767px;
$tablet-lg-width: 992px;
$desktop-width: 1200px;
$desktop-lg-width: 1600px;
$desktop-superlg-width: 1920px;


@mixin phone-xs-only {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin phone-xs {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin phone-only {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin phone-xs-products {
  @media (max-width: #{$product-card-width*2-1}) {
    @content;
  }
}

@mixin phone-lg-products {
  @media (min-width: #{$product-card-width*2}) and (max-width: 767px) {
    @content;
  }
}

@mixin hi-dpi {
  /* 1.5 dpr */
  @media 
    (-webkit-min-device-pixel-ratio: 1.5), 
    (min-resolution: 144dpi){ 
      @content;
    }
}

@mixin tablet-phone-only {
  @media (max-width: #{$tablet-lg-width - 1px}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet-width + 1px}) and (max-width: #{$tablet-lg-width - 1px}) {
    @content;
  }
}

@mixin tablet-lg-only {
  @media (min-width: #{$tablet-lg-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop-only {
  @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-lg-width - 1px}) {
    @content;
  }
}

@mixin phone-lg {
  @media (min-width: #{$phone-width + 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width + 1px}) {
    @content;
  }
}

@mixin tablet-lg {
  @media (min-width: #{$tablet-lg-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
} 

@mixin desktop-lg {
  @media (min-width: #{$desktop-lg-width}) {
    @content;
  }
}
@mixin desktop-superlg {
  @media (min-width: #{$desktop-superlg-width}) {
    @content;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}

// @include desktop {
//   & {

//   }
// }

// @include tablet-lg-only {
//   & {
 
//   }
// }

// @include placeholder {
//     font-style:italic;
//     color: white;
//     font-weight:100;
// }